<template>
  <template v-if="tooltipMsg">
    <TopbarTooltip :message="tooltipMsg">
      <button type="button" :class="classes" class="text-center font-medium rounded" v-bind="$attrs" :readonly="props.readonly" @click="onClick"><slot></slot></button>
    </TopbarTooltip>
  </template>
  <template v-else>
    <button type="button" :class="classes" class="text-center font-medium rounded" v-bind="$attrs" :readonly="props.readonly" @click="onClick"><slot></slot></button>
  </template>
</template>
<script lang="ts" setup>

import {computed} from "vue";
import TopbarTooltip from "../../../topbar/components/TopbarTooltip.vue"

const props = withDefaults(defineProps<{
  primary?: boolean
  textSm?: boolean
  padding?: boolean
  readonly?: boolean
  tooltipMsg?: string
}>(), {
  primary: false,
  textSm: true,
  padding: true
});

const emit = defineEmits(['action'])

const onClick = (event: MouseEvent) => {
  if(props.readonly) {
    event.preventDefault();
    event.stopPropagation();
    return true;
  } else emit('action')
}

const classes = computed(() => {
  let result: string[] = [];

  if(props.primary) result.push("bg-primary-500", "hover:bg-primary-300", "text-white")
  else result.push("border", "border-primary-500", "text-primary-500", "hover:bg-secondary-100")
  if(props.textSm) result.push("text-sm")
  if(props.padding) result.push("px-4 py-2")

  if(props.readonly) {
    result = result.filter(item => !item.startsWith("hover:"))
    result.push("cursor-not-allowed")
  }

  return result;
})
</script>
<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "DButton",
  inheritAttrs: false
})
</script>
